import React from 'react';
import { LocalizedLink as Link } from 'gatsby-theme-i18n';
import { useTranslation } from 'react-i18next';
import LineDash from '../components/LineDash';

const News = ({ pageContext: { locale: language } }) => {
  const { t } = useTranslation('news');
  const news = t('news', { returnObjects: true });
  return (
    <div className="bg-white overflow-hidden">
      <LineDash title={t('title')}>
        <div className="flex max-w-5xl mx-auto my-7 px-8 flex-col gap-8">
          {news.map((item) => (
            <div className="relative rounded overflow-hidden">
              <Link to={item.href}>
                <svg className="absolute" width="100%" height="100%">
                  <line
                    x1="0%"
                    y1="0%"
                    x2="100%"
                    y2="0%"
                    pathLength="100"
                    stroke="#e1c466"
                    stroke-width="10px"
                    stroke-dasharray="25 5 25 20 25"
                  />
                  <line
                    x1="100%"
                    y1="0%"
                    x2="100%"
                    y2="100%"
                    pathLength="100"
                    stroke="#e1c466"
                    stroke-width="10px"
                    stroke-dasharray="30 20 20 10 20"
                  />
                  <line
                    x1="100%"
                    y1="100%"
                    x2="0%"
                    y2="100%"
                    pathLength="100"
                    stroke="#e1c466"
                    stroke-width="10px"
                    stroke-dasharray="25 5 25 20 25"
                  />
                  <line
                    x1="0%"
                    y1="100%"
                    x2="0%"
                    y2="0%"
                    pathLength="100"
                    stroke="#e1c466"
                    stroke-width="10px"
                    stroke-dasharray="30 20 20 10 20"
                  />
                </svg>
                <div className="flex flex-col lg:flex-row py-8">
                  <div className="relative ml-5 mb-5 min-w-28 max-w-28 min-h-20 max-h-20 text-zinc-400">
                    <svg
                      width="100%"
                      height="100%"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line
                        x1="15%"
                        y1="58%"
                        x2="48%"
                        y2="10%"
                        class="stroke-current stroke-1"
                      />
                    </svg>
                    <div className="absolute left-0 top-0 text-3xl font-light">
                      <p>{item.date.split('/')[0]}</p>
                    </div>
                    <div className="absolute bottom-0 right-0 text-7xl font-light">
                      <p>{item.date.split('/')[1]}</p>
                    </div>
                  </div>
                  <div className="mx-10 text-base font-light text-neutral-600">
                    <p>{item.text}</p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </LineDash>
    </div>
  );
};

export default News;
