import React from 'react';
import HeroImage from '../images/hero-image.png';
import { LocalizedLink as Link } from 'gatsby-theme-i18n';
import { useTranslation } from 'react-i18next';
import { Parallax } from 'react-scroll-parallax';

const Hero = ({ pageContext: { locale: language } }) => {
  const { t } = useTranslation('index');
  return (
    <div className="">
      <div className="relative w-full max-w-7xl m-auto object-center overflow-hidden">
        <Parallax scale={[1, 1.5, 'easeInQuad']}>
          <div>
            <img
              className="w-full h-[32rem] object-cover"
              src={HeroImage}
              alt=""
            />
          </div>
        </Parallax>
        <div className="absolute inset-0 max-w-5xl m-auto">
          <div className="flex flex-col">
            <div className="flex mx-4 my-2">
              <h3 className="text-3xl text-white">{t('hero.title')}</h3>
            </div>
            <div className="flex mx-4 my-0">
              <h4 className="text-base text-white">{t('hero.feature.1')}</h4>
            </div>
            <div className="flex mx-4 my-0">
              <div>
                <svg
                  width="200"
                  height="150"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="10"
                    y1="65"
                    x2="90"
                    y2="65"
                    stroke="white"
                    stroke-width="2"
                  />
                  <line
                    x1="105"
                    y1="65"
                    x2="170"
                    y2="65"
                    stroke="white"
                    stroke-width="2"
                  />
                  <circle
                    cx="170"
                    cy="65"
                    r="5"
                    class="fill-current text-white"
                  />
                  <line
                    x1="10"
                    y1="66"
                    x2="60"
                    y2="10"
                    stroke="white"
                    stroke-width="2"
                  />
                  <line
                    x1="70"
                    y1="10"
                    x2="130"
                    y2="130"
                    stroke="white"
                    stroke-width="2"
                  />
                  <circle
                    cx="130"
                    cy="130"
                    r="5"
                    class="fill-current text-white"
                  />
                </svg>
              </div>
              <div className="flex mx-4 my-auto">
                <h4 className="text-base text-white">{t('hero.feature.2')}</h4>
              </div>
            </div>
            <div className="flex mx-24 my-0">
              <h4 className="text-base text-white">{t('hero.feature.3')}</h4>
            </div>
            <div className="flex mx-4 my-0">
              <Parallax rotate={[0, 0]} speed={0}>
                <div>
                  <svg
                    width="200"
                    height="90"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      x1="70"
                      y1="80"
                      x2="130"
                      y2="10"
                      stroke="white"
                      stroke-width="2"
                    />
                    <circle
                      cx="70"
                      cy="80"
                      r="5"
                      fill="#fefefe"
                      srtoke-width="0"
                    />
                    <circle
                      cx="130"
                      cy="10"
                      r="5"
                      fill="#fefefe"
                      srtoke-width="0"
                    />
                  </svg>
                </div>
              </Parallax>
            </div>
            <div className="flex mx-4 my-0">
              <h4 className="text-base text-white">{t('hero.feature.4')}</h4>
            </div>
            <div className="flex mx-4 my-1 w-fit">
              <Link
                key="contact"
                to="/contact"
                className="w-full flex py-1 px-4 items-center justify-center text-sm font-normal rounded-lg text-white bg-[#6185b0] hover:bg-blue-700"
              >
                {t('hero.cta1')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
