import React from 'react';

const LineDash = ({ title, children }) => {
  return (
    <div className="bg-white overflow-hidden">
      <div className="flex flex-col mx-auto my-7">
        <div className="relative m-auto w-full max-w-xl my-7">
          <div className="">
            <svg width="100%" height="50" xmlns="http://www.w3.org/2000/svg">
              <line
                x1="5%"
                y1="50%"
                x2="50%"
                y2="50%"
                stroke="#6185b0"
                stroke-width="2"
              />
              <circle cx="5%" cy="50%" r="5" fill="#6185b0" />
              <line
                x1="50%"
                y1="50%"
                x2="95%"
                y2="50%"
                stroke="#6185b0"
                stroke-width="2"
                stroke-dasharray="18 5"
              />
              <circle
                cx="95%"
                cy="50%"
                r="5"
                stroke="#6185b0"
                stroke-width="2"
                fill="white"
              />
            </svg>
          </div>
          <div className="absolute inset-0 w-fit h-fit m-auto p-3 bg-white">
            <h3 className="text-xl text-[#6185b0] font-medium">{title}</h3>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default LineDash;
