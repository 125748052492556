import * as React from 'react';
import Layout from '../components/layout';
import Hero from '../components/Hero';
import News from '../components/News';

const IndexPage = () => {
  return (
    <Layout>
      <Hero pageContext="{locale: language}" />
      <News pageContext="{locale: language}" />
    </Layout>
  );
};

export default IndexPage;
